@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "https://unpkg.com/open-props";

:root{
  --bg-color-1: #BCEBED;
  --bg-color-2: #8BD9DC;
  --bg-color-3: #7DD3CB;
  --quote-text-color: #175950;
  --root-fs: 16px;
  --ratio: 0.75;
  --p-fs: var(--root-fs);
  --h1-fs: calc(var(--root-fs) * 3);
  --h2-fs: calc(var(--h1-fs) * var(--ratio));
  --h3-fs: calc(var(--h2-fs) * var(--ratio));
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: var(--h1-fs);
  margin-bottom: var(--size-3);
}

h2{
  font-size: var(--h2-fs);
  margin-bottom: var(--size-3);
  text-align: center;
}

h3{
  font-size: var(--h3-fs);
  margin-top: var(--size-3);
  margin-bottom: var(--size-3);
}

p{
  font-size: var(--p-fs);
  margin-bottom: var(--size-3);
}

.text-content-container p, .text-content-container h3, .text-content-container ul{
  margin-left: var(--size-fluid-3);
  margin-right: var(--size-fluid-3);
}

ul{
  margin-bottom: var(--size-3);
}

li{
  font-size: var(--p-fs);
  margin-left: 3rem;
}

.text-content-container{
  margin: 0 var(--size-fluid-3);
  margin-top: var(--size-3);
}

.center-grid{
  display: grid;
  place-content: start center;
}

.quote-block p {
  font-size: var(--h3-fs);
  color: var(--quote-text-color);
}

p.quote{
  font-weight: 600;
}

.quote-block{
  display: grid;
  place-items: center;
  margin: var(--size-6);
}

.flex{
  display: flex;
}

.flex-column{
  flex-direction: column;
}

.flex-center{
  justify-content: center;
  align-items: center;
}

.relative{
  position: relative;
}

.absolute{
  position: absolute;
}

img{
  width: 50em;
}

#two-svg{
  width: 30em;
}

/* Control group */
.control-group{
  margin-top: 10rem;
}

.control-item{
  border: solid black 4px;
  border-radius: 100%;
  display: grid;
  place-content: center;
  aspect-ratio: 1/1;
  min-width: 44px;
  padding: 0.4rem;
  margin: 0.5rem;
  background-color: hsla(0, 0%, 100%);
}

.control-item:hover{
  background-color: coral;
  cursor: pointer;
}

#top-img{
  z-index: -1;
  opacity: 0.95;
}

.placeholder{
  background-color: azure;
  width: 80vw;
  height: 20vh;
  place-content: center;
}

@media only screen and (min-width: 1024px) {
  .text-content-container{
    margin: 0 var(--size-fluid-8);
    margin-top: var(--size-8);
  }

  .text-content-container p, .text-content-container h3, .text-content-container ul{
    margin-left: var(--size-fluid-6);
    margin-right: var(--size-fluid-6);
  }

  .placeholder{
    width: 50em;
    height: 20em;
  }
}